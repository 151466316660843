import * as React from "react";

const ToolsIcon = ({ className, alt }: ThemedIconProps): JSX.Element => {
  return (
    <svg
      className={className ? className : ""}
      viewBox="0 0 89 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <desc>{alt ? alt : ""}</desc>
      <path
        d="M35.225 41.7316L3.41003 73.5466C2.57324 74.3825 1.90941 75.3751 1.45649 76.4676C1.00357 77.5602 0.770447 78.7314 0.770447 79.9141C0.770447 81.0968 1.00357 82.268 1.45649 83.3606C1.90941 84.4532 2.57324 85.4458 3.41003 86.2816C5.09778 87.9689 7.38655 88.9167 9.77303 88.9167C12.1595 88.9167 14.4483 87.9689 16.136 86.2816L47.951 54.4576M67.3685 56.1001L84.8195 73.5511C86.5068 75.2389 87.4546 77.5276 87.4546 79.9141C87.4546 82.3006 86.5068 84.5894 84.8195 86.2771C83.1318 87.9644 80.843 88.9122 78.4565 88.9122C76.07 88.9122 73.7813 87.9644 72.0935 86.2771L44.153 58.3411M20.2985 20.5681L10.745 23.7451L1.20503 7.83761L7.56803 1.47461L23.48 11.0191L20.2985 20.5681ZM20.2985 20.5681L33.0335 33.3031"
        stroke="#3E3D3D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.225 41.7316C31.427 32.0431 32.1695 19.3306 39.9995 11.5006C47.8295 3.67055 62.2745 1.95605 70.226 6.73055L56.546 20.4106L55.2725 34.4101L69.272 33.1411L82.952 19.4566C87.731 27.4126 86.012 41.8575 78.182 49.683C70.352 57.513 57.644 58.2556 47.9555 54.4576"
        stroke="#3E3D3D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ToolsIcon;
