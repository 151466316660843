import * as React from "react";

//leaderboard-star-icon.tsx
const LeaderboardStarIcon = ({
  className,
  alt,
}: ThemedIconProps): JSX.Element => {
  return (
    <svg
      className={className ? className : ""}
      viewBox="0 0 84 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <desc>{alt ? alt : ""}</desc>
      <path
        d="M55.5 82.5001H28.5M55.5 82.5001V44.7001C55.5 43.984 55.2155 43.2972 54.7092 42.7909C54.2028 42.2845 53.5161 42.0001 52.8 42.0001H31.2C30.4839 42.0001 29.7972 42.2845 29.2908 42.7909C28.7845 43.2972 28.5 43.984 28.5 44.7001V82.5001M55.5 82.5001H79.8C80.5161 82.5001 81.2028 82.2156 81.7092 81.7093C82.2155 81.2029 82.5 80.5162 82.5 79.8001V69.4501C82.5 68.734 82.2155 68.0472 81.7092 67.5409C81.2028 67.0345 80.5161 66.7501 79.8 66.7501H58.2C57.4839 66.7501 56.7972 67.0345 56.2908 67.5409C55.7845 68.0472 55.5 68.734 55.5 69.4501V82.5001ZM28.5 82.5001V60.4501C28.5 59.734 28.2155 59.0472 27.7092 58.5409C27.2028 58.0345 26.5161 57.7501 25.8 57.7501H4.2C3.48392 57.7501 2.79716 58.0345 2.29081 58.5409C1.78446 59.0472 1.5 59.734 1.5 60.4501V79.8001C1.5 80.5162 1.78446 81.2029 2.29081 81.7093C2.79716 82.2156 3.48392 82.5001 4.2 82.5001H28.5ZM36.627 11.0086L40.7175 2.33707C40.8279 2.08918 41.0078 1.8786 41.2355 1.73082C41.4631 1.58304 41.7286 1.50439 42 1.50439C42.2714 1.50439 42.5369 1.58304 42.7645 1.73082C42.9922 1.8786 43.1721 2.08918 43.2825 2.33707L47.3775 11.0086L56.5215 12.4081C57.696 12.5881 58.164 14.1001 57.3135 14.9641L50.6985 21.7141L52.26 31.2451C52.458 32.4691 51.234 33.4051 50.181 32.8246L42 28.3246L33.819 32.8246C32.7705 33.4006 31.542 32.4691 31.74 31.2451L33.3015 21.7141L26.6865 14.9641C25.8315 14.1001 26.304 12.5881 27.474 12.4081L36.627 11.0086Z"
        stroke="#3E3D3D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LeaderboardStarIcon;
